.app__navbar{
    width:100%;
    display:flex;
    /* border:1px solid black; */
    justify-content:space-between;
    align-items:center;
    background:rgb(10, 10, 10);
    padding:0.5rem 2rem;
    position:fixed;
    z-index: 100;
    top:0;
    left:0;
}
.app__navbar-logo{
    display:flex;
    justify-content:flex-start;
    align-items:center;
    width:100%;
}
.app__navbar-logo img{
    width: 120px;
}

.app__navbar-logo-mob{
    display:none;
}
.app__navbar-links{
    direction:rtl;
    flex: 1;
    display:flex;
    justify-content:center;
    align-items:center;
    list-style: none;
    padding: 0;
}
.app__navbar-links li{
    margin: 0 1rem;
    cursor:pointer;
}
.p__opensans a{
    font-family:'Cooperative' !important;
    color:white !important;
    font-size: 22px;

}



.p__opensans a:hover{
    color:rgba(30, 78, 110, 1)  !important;
    text-decoration: none;
}

.p__opensans a:focus{
    color:rgba(30, 78, 110, 1)  !important;
    text-decoration: none;
    font-size: 22px;

}



.nav___button{
    padding: 8px 12px;
    font-size:22px;
    margin:0px 4px;
    border:1px solid white;
    border-radius:5px;
    font-family:'Cooperative' !important;

}


.p__opensans .table{
    color:#c3892d !important;

}

.p__opensans .order{
    color:#6abfb7 !important;
}





.rs-dropdown-toggle, .rs-dropdown-toggle.rs-btn{
    background:none;
    padding: 0;
    padding-left: 7px;
    margin:0;
    vertical-align: 1.5px;
    color:white !important;
    direction: rtl !important;

}
.rs-dropdown-toggle-caret{
    top: 3px !important;
}
.rs-btn{
    font-size:22px !important;
    line-height:28px !important;
}
.rs-btn:hover{
    font-size:22px !important;
    line-height:28px !important;
    color:rgba(30, 78, 110, 1)  !important;

}
.p__opensans{
    font-family:'Cooperative' !important;
}
a{
    font-family:'Cooperative' !important;   
}


.app__navbar-smallscreen{
    display:none;
}
.app__navbar-smallscreen_overlay{
    position:fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background:rgb(10, 10, 10);
    transition: all 1s ease-in;
    -moz-transition: all 1s ease-in;
    -webkit-transition: all 1s ease-in;
    -o-transition: all 1s ease-in; 
    flex-direction: column;
    z-index: 5;
    text-align: center !important;

}
.app__navbar-smallscreen_links {
    list-style: none;
    margin-top: 10rem;
    flex-direction: column;
    text-align: center !important;
}
.app__navbar-smallscreen_links li {
    display: flex;
    justify-content:center;
    align-items:center;
    margin: 2rem;
    cursor: pointer;
    color: var(--color-golden);
    font-size: 2rem;
    text-align: center !important;
    font-family: var(--font-base);
}
.app__navbar-smallscreen_links .sub li {
    margin: 3px;
    cursor: pointer;
    color: rgba(30, 78, 110, 1) ;
    font-size: 16px;
    text-align: center;
    font-family: var(--font-base);
}
.app__navbar-smallscreen_overlay .overlay__close{
    font-size:27px;
    color:#c3892d !important; 
    cursor: pointer;
    position: absolute;
    top:30px;
    right:20px;
}
.humburger{
    color:#c3892d !important; 
    position: fixed;
    top:30px;
    right:20px;
}
.app__navbar-menu{
    display:none;
}
@media screen and (max-width:2000px) {
    .app__navbar-logo{
        width:210px;
    }
}

@media screen and (max-width:1150px) {
    .app__navbar-links{
        display: none;
    }

    .app__navbar-smallscreen{
        display: flex;
    text-align: center !important;

    }
    .rs-dropdown{
        margin:0 !important;
        padding:0 !important;
    }
    .rs-dropdown-item{
        font-size:16px !important;
    }
    .rs-dropdown-menu[hidden]{
        margin:0 !important;
        padding:0 !important; 
    }
    /* .rs-dropdown .rs-dropdown-menu{
        right: 10px;
    } */
    /* .rs-dropdown .rs-btn>.rs-dropdown-toggle-caret svg{
        display:none;
    } */
}   




@media screen and (max-width:650px) {
    .app__navbar-logo-mob img{
        width:90px;
 
    }
    .app__navbar-logo{
        display:none;
    }
    .app__navbar-logo-mob{
        display:block;
        justify-content:flex-start;
        align-items:center;
        width:90px;
    }
    .app__navbar{
        width:100%;
        display:flex;
        border:none;
        justify-content:space-between;
        align-items:center;
        background:rgb(10, 10, 10);
        padding:0.5rem 0.5rem;
    }
    .app__navbar-smallscreen{
        width:100%;
        display:flex;
        border:none;
        justify-content:space-between;
        align-items:center;
        background:rgb(10, 10, 10);
        padding:0.5rem 1rem;
    }
    .app__navbar-menu{
        display:flex;
        justify-content: flex-end;
        display:block;
        width: 25%;

    }
    .app__navbar-seat{

        justify-content: center;
        align-items: center;
        
    }
    .app__navbar-links-order{
        justify-content: center;
        flex-direction: column;
        list-style: none;
        align-items: center;
        text-align: center;
        margin:0;
        padding: 0;
    }

    .p__opensans .table{
        font-size: 18px;
    
    }
    
    .p__opensans .order{
        font-size: 18px;
    }
    .p__opensans .table:hover, .p__opensans .table:focus{
        font-size: 18px;
    
    }
    
    .p__opensans .order:hover,.p__opensans .order:focus {
        font-size: 18px;
    }
}
.headtext-cormorant-news{
    color: white;
    font-size:40px;
    font-family: 'Cooperative';
    letter-spacing:2px;
    margin-bottom:20px;
    text-align:center;
    direction:rtl;
}
.app__newsletter{
    display: flex;
    justify-content:center;
    align-items:center;
    flex-direction:column;
    border:1px solid #c3892d;
    background:black;
    padding:2rem 4rem;
    border-radius:3px;
}


.app__form{
    display: flex;
    flex-direction:column;
    margin:0;
    padding:0;
    width:100%;
    direction:rtl;
    justify-content:center;
    align-items:center;
}
.app__form-name{
    display: flex;
    flex-direction:row;
}
.inputs{
    font-family: 'NarkissBlock';
    font-size:20px;
    font-weight: 300;
    padding: 4px 15px 4px 55px;
    background-color:rgba(255,255,255,0);
    border:1px solid #c3892d;
    border-radius:2px;
    margin:12px 7px;
    color:white;

}
input::placeholder{
    color: white;
}
.btn{
    background:#c3892d;
    color:white;
    border:2px solid #c3892d;
    border-radius:2px;
    width:150px;
    text-align:center;
    padding: 4px 55px 4px 55px;
    
}

.btn:hover{
    background:white;
    color:#c3892d;
    border-radius:2px;

}
.input__error{
    font-family: 'NarkissBlock';
    font-size: 15px;
    text-align: end;
    margin-left:30px;
    color:white;

}   
@media screen and (max-width:650px){
      .headtext-cormorant-news{
        font-size:25px;
        margin-bottom:0px;
        line-height:30px;

      }
      .inputs{
        font-size:18px;
        padding: 4px 15px 4px 25px;
        background-color:rgba(255,255,255,0);
        border:1px solid #c3892d;
        border-radius:2px;
        margin:4px 7px;
        color:white;
    
    }
      .app__newsletter{
        width:100%;
        margin:5px;
        padding:1rem 1rem;

      }
      .btn{
        background:#c3892d;
        color:white;
        border:2px solid #c3892d;
        padding: 4px 15px 4px 15px;
        margin-top:15px;
    }
    .headtext-cormorant-news{
        color: white;
        font-size:22px;
        font-family: 'Cooperative';
        letter-spacing:2px;
        margin-bottom:5px;
        text-align:center;
        direction:rtl;
    }
    .app__newsletter-heading{
        width:100%;

    }
    .app__form-name, .app__form-email{
        display: flex;
        flex-direction:column;
    }
    .input__error{
        font-family: 'NarkissBlock';
        font-size: 15px;
        text-align: center;
        margin-left:0;
        color:white;
    }
  }
.app__menuitem{
    width: 100%;
    margin:1rem 0;
    display: flex;
    flex-direction: column;
}

.p__coop{
    font-family: 'Cooperative';
    font-size:1.65vw;
}

.p__narkis{
    font-family: 'NarkissBlock';
    font-size:1.35vw;

}
.app__menuitem-head{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.app__menuitem-sub{
    width:100%;
    margin-top:0.2rem;
}
.app__menuitem-name{
    flex: 1;
}
/* .app__menuitem-dash{
    width:45%;
    height: 1px;
    background-color:white;
    margin: 0 1rem;
} */

.app__menuitem-price{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction:row;
}

@media screen and (max-width:1150px){

}
@media screen and (max-width:650px){
    .p__coop{
        font-size:28px;
        letter-spacing:2px;

    }
    .p__narkis{
        font-size:20px;
        
    }
}
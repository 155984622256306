.app__gallery{
    display: flex;
    flex-direction: row;
    background-color:black;
    padding:4rem 6rem 4rem 0 ;
    direction:rtl;
}
.headtext-cormorant{
    color: white;
    font-size:50px;
}
.right {
    font-family:'NarkissBlock';
    color:white;
    direction: rtl;
    font-weight: 300;
    letter-spacing: 0.04em;
    line-height: 28px;
    font-size: 16px;
}
.insta{
    margin-top:10px;
    display:flex;
    justify-content:space-between;
    align-items:center;
}
.day{
    padding-left: 5px;
}

.app__gallery-content {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;

    min-width: 500px;
    padding-right: 2rem;
}

.app__gallery-content button {
    margin-top: 1rem;
}

.app__gallery-images {
    flex: 1;
    display: flex;
    flex-direction: row;
    max-width: 50%;
    position: relative;
}

.app__gallery-images_container {
    display: flex;
    flex-direction: row;
    width: max-content;
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;  
}

.app__gallery-images_container::-webkit-scrollbar {
    display: none;
}

.app__gallery-images_card {
    position: relative;
    min-width: 301px;
    height: 447px;
    margin-right: 2rem;
}

.gallery__image-icon {
    position: absolute;
    top:50%;
    right: 50%;
    color: #fff;
    font-size: 2rem;
    opacity: 0;
    transition: .5s ease;
    cursor: pointer;
}

.app__gallery-images_card img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 1;
    transition: .5s ease;
}

.app__gallery-images_card:hover img {
    opacity: .35;
}

.app__gallery-images_card:hover .gallery__image-icon {
    opacity: 1;
}

.app__gallery-images_arrows {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 0 3rem;
    position: absolute;
    bottom: 45%;
}

.gallery__arrow-icon {
    color: #c3892d;
    font-size: 3rem;
    cursor: pointer;
    background: black;
    border-radius: 5px;
}

.gallery__arrow-icon:hover {
    color: white;
}

@media screen and (min-width: 2000px) {
    .app__gallery-content button {
        margin-top: 2rem;
    }

    .app__gallery-content {
        min-width: 1000px;
        padding-right: 4rem;
    }

    .app__gallery-images_card {
        min-width: 400px;
        height: 547px;
    }
}

@media screen and (max-width: 1150px) {
    .app__gallery {
        flex-direction: column;
    }

    .app__gallery-images {
        max-width: 100%;
        margin: 5rem 0;
    }
}

@media screen and (max-width: 850px) {
    .app__gallery {
        padding: 4rem 0 4rem 4rem;
    }
}

@media screen and (max-width: 650px) {
    .app__gallery {
        padding: 4rem 0 4rem 0;
    }  
    
    .app__gallery-content {
        min-width: 100%;
    }
    .app__gallery-images_arrows{
        padding:5px;
    }
    .app__gallery-images_card {
        min-width: 240px;
        height: 320px;
    }
}

a{
    text-decoration:none;
}
.links{
    text-decoration:none;

}
.links:hover{
    text-decoration:none;

}
.right {
    font-family:'NarkissBlock';
    color:white;
    direction: rtl;
    font-weight: 300;
    letter-spacing: 0.04em;
    line-height: 28px;
    font-size: 16px;
}
 .left{
    font-family:'NarkissBlock';
    color:white;
    direction: ltr;
    font-weight: 300;
    letter-spacing: 0.04em;
    line-height: 28px;
    font-size: 16px; 
}
.to-right{
    padding-right:20px;
    direction:rtl !important;
}
.headtext-cormorant{
    color: white;
    font-size:50px;
}
.app__aboutus{
    position:relative;
}
.app__aboutus-overlay{
    position:absolute;
    inset:0;
}
.app__aboutus-overlay img{
    width:291px;
    height: 415px;
}

.app__aboutus-content{
    width:100%;
    z-index: 2;
}

.app__aboutus-content_about{
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    flex-direction: column;
}

.app__aboutus-content_knife{
    margin:1rem 3rem;
}
.app__aboutus-content_knife img{
    height: 700px;
}
.app__aboutus-content_history{
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    flex-direction: column; 
}

.app__aboutus-content_history p,.app__aboutus-content_about p{
    margin: 2rem 1rem;
}


@media screen and (max-width:2000px){
    .app__aboutus-content_knife img{
        height: 800px;
    }
    .app__aboutus-content_history p,.app__aboutus-content_about p{
        margin: 4rem 1rem;
    } 
}


@media screen and (max-width:900px){
    .app__aboutus-content{
        flex-direction: column;
    }
    .app__aboutus-content_knife{
        margin:4rem 0;
    }
    .app__aboutus-content_knife img{
        height: 700px;
    }

}
@media screen and (max-width:650px){
    .app__aboutus-overlay  img{
        width: 220px;
        height: 340px;
    }
    .left{
        direction: rtl;

    }
    .app__aboutus-content_history p,.app__aboutus-content_about p{
        margin: 0;
    }
    .app__aboutus-content_history{
        align-items: flex-end;
    }
    .to-right{
        padding-right:0px;
        direction:rtl !important;
    }
}
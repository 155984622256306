.headtext-cormorant-contact{
    color: white;
    font-size:60px;
    font-family: 'Cooperative';
    letter-spacing:2px;
    margin-bottom:20px;
}
.contact-line{
    font-family: 'NarkissBlock';
    margin:1rem 0;
    color:white;
    font-size:25px;
    line-height:25px;
    font-weight:100;
}
.social-find{
    display:flex;
    flex-direction:row;
    justify-content: flex-start;
    align-items:space-between;
}
.icons{
    margin-top: 1rem;
    margin-left:1rem;
    margin-bottom: 1rem;
}

.app__wrapper_info-contact {
    flex: 1;
    width:50%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    direction: rtl;
  }
  
  .app__wrapper_img-contact {
    flex: 1;
    width:50%;

    display: flex;
    justify-content: center;
    align-items: center;
  
    margin-left: 2rem;
  }
  
  .app__wrapper_img-contact-reverse {
    justify-content: flex-start;
    margin-right: 2rem;
  }
  
  .app__wrapper_img-contact img {
    width: 500px;
  }

  .links-btn{
    text-decoration:none;

  }
  .links-btn:hover{
    text-decoration:none;
  }
  @media screen and (max-width:1150px){
    .app__wrapper_info-contact{
        width:100%;
        margin-bottom: 1rem;
    }
    
  }
  @media screen and (max-width:650px){
    .app__wrapper_img-contact img {
        width: 300px;
      }
      .app__wrapper_img-contact{
        margin:0;
      }
      .headtext-cormorant-contact{
        font-size:40px
      }
      .contact-line{
        font-size:20px;
      }
      .app__wrapper-content{
        justify-content: center;
        align-items: center;
      }
  }
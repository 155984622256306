.app__header{
    direction:rtl;
    background: url('../../assets/test4.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: repeat;
    background-attachment: fixed;
    /* margin-top:70px; */
}

.app__header-h1{
    font-size: 90px;
    font-family:'Cooperative';
    letter-spacing:0.04em;
}

.gold{
    color:#c3892d;
    line-height:115px;
}

.turkiz{
    color:#6abfb7;
    /* padding-right:10px; */
}
.hero-line{
    margin:2rem 0;
    color:white;
    font-size:20px;
    line-height:25px;
    font-weight:100;
}
.app__header-image img{
    width:80%;
}

#all_buttons{
    background: none;
    color: #fff;
    border: 1px solid #c3892d;
    font-weight: 300;
    letter-spacing: 0.04em;
    line-height: 28px;
    font-size: 16px;
    padding: 0.5rem 1.5rem;
    border-radius: 1px;
    position: relative;
    z-index: 1;
    overflow: hidden;
    cursor: pointer;
}


@media screen and (max-width: 650px){
    .gold{
        color:#c3892d;
        line-height: 87px;
        margin-bottom: 12px;
    }
    .app__header{
        direction:rtl;
        background: url('../../assets/phone_bg.jpg');
        background-position: center;
        background-size: cover;
        /* background-repeat: repeat; */

        
    }
    .mob-push{
        margin-top:70px;
    }


}
.app__specialMenu{
    flex-direction: column;
    background:black;
}


.app__specialMenu-title{
    margin:2rem;
    text-align:center;
}
.headtext-cormorant{
    color: white;
    font-size:60px;
    font-family: 'Cooperative';
    letter-spacing:5px;

}
.app__specialMenu-menu{
    width:100%;
    margin:2rem 0;
    display: flex;
    justify-content:center;
    align-items:flex-start;
    flex-direction:row;
    direction:rtl;
}


.app__specialMenu-menu_image{
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items:center;
    /* overflow-x: hidden; */
}

.app__specialMenu-menu_heading{
    font-family: 'NarkissBlock';
    font-size:1.65vw;
    color:white;
    text-align:center;

}

.app__specialMenu-menu_wine , .app__specialMenu-menu_cocktails{
    flex:1;
    width:100%;
    flex-direction:column;
}

.app__specialMenu-menu_image-lemon{
    width:140px;

}
.app__specialMenu-menu_image-shaker{
    width:500px;
    z-index:4;
}
.app__specialMenu-menu_items{
    display:flex;
    flex-direction:column;
    margin: 1rem 0;
    width: 100%;
}
.sp_btn{
    display:flex;
    justify-content:center;
    align-items:center;
}
.links-btn{
    text-decoration:none;

  }
  .links-btn:hover{
    text-decoration:none;
  }
@media screen and (max-width:2000px){
    .app__specialMenu-menu{
        /* flex-direction: column; */
        align-items:center;
        width:100%;
    }
}
@media screen and (max-width:1150px){
    .app__specialMenu-menu{
        width:100%;
        align-items:center;
        flex-direction:column;
        
    }
    .headtext-cormorant{
        color: white;
        font-size:40px;
        letter-spacing:3px;

    }
}
@media screen and (max-width:650px){
    .app__specialMenu{
        overflow:hidden;
    }

    .app__specialMenu-menu{
    margin:0;
    
    }
    .app__specialMenu-menu_image-lemon{
        width:70px;
    
    }
    .app__specialMenu-menu_image-shaker{
        width:300px;
        z-index:4;
    }
    .app__specialMenu-menu_heading{
        font-size:5.65vw;
    
    }
}
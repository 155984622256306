.app__footer {
    width: 100%;
    position: relative;
    z-index: 1;

    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;

    background: rgb(10, 10, 10);
    padding-top: 0;
}





.app__footer-links{
    width: 100%;
    display: flex;
    justify-content:space-between;
    align-items:flex-start;
    margin:2rem;
    padding: 0 2rem;
    flex-direction:row;
}

.links{
    text-decoration: none;
    font-family: 'NarkissBlock';
    font-weight:200;
    color:white;
    font-size:20px;
    margin:0;
    padding: 0;
    line-height:30px;
}
.app__footer-headtext{
    color:white;
    font-size:1.5rem;
    line-height:30px;
    text-align:center;
}
.links:hover{
    color:#6abfb7 ;

}

.p_tzru{
    margin:0;
    padding: 0;
    text-align:center;
    font-family: 'NarkissBlock';
    font-weight:200;
    color:white;
    font-size:16px;
    line-height:20px;

}


.p__copy{
    font-family: 'NarkissBlock';
    color:white;
    font-size: 14px;
    margin-top:20px;
}

.app__footer-links_contact{
    flex:1;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
}
.app__footer-links_logo{
    flex:1;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;

}
.app__footer-links_work{
    flex:1;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;

}
.social{
    display:flex;
    flex-direction:row;
    justify-content: center;
    align-items:space-between;
}
.icons{
    margin-top: 1rem;
    margin-left:1rem;
    margin-bottom: 1rem;
}


.footer__logo{
    width:200px;
    margin-bottom:20px;
}

.footer__fixes{
    display:flex;
    width:100%;
    align-items:center;
    justify-content:space-between;
}

.footer__left{
    position: fixed;
    bottom:20px;
    left:20px;
}

.links-btn{
    text-decoration:none;

}
.links-btn:hover{
    text-decoration:none;
}

@media screen and (max-width:1150px){}
@media screen and (max-width:650px){
    .app__footer-links{
        width: 100%;
        display: flex;
        justify-content:space-between;
        align-items:center;
        margin:1rem;
        padding: 0 1rem;
        flex-direction:column;
    }
    .footer__logo{
        margin-top:10px;
        margin-bottom: 10px;
    }
}